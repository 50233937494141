import Api from '@/lib/api'

let api: Api

export const useApi = () => {
  if (!api) {
    api = new Api()
    
  }
  return api
}