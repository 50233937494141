import { useToast } from "primevue/usetoast";

class Api {
    private nuxtApp: ReturnType<typeof useNuxtApp>;
    private xsrfToken: ReturnType<typeof useCookie>;
    private toast: ReturnType<typeof useToast>


    constructor() {
        this.nuxtApp = useNuxtApp();
        this.xsrfToken = useCookie('XSRF-TOKEN');
        this.toast = useToast();
    }

    private async refreshCsrfToken(): Promise<void> {
        if (!this.xsrfToken.value) {
            await this.nuxtApp.$csrf();
        }
    }

    public async execute(endpoint: string, method: string, body?: any): Promise<any> {
        await this.refreshCsrfToken();

        try {
            const response = await this.nuxtApp.$apiFetch(endpoint, {
                method,
                body,
            });

            return response;
        } catch (error) {

            if (error?.response?.status === 419) {
                console.log('419');
                if (!this.nuxtApp.$csrf.retryAttempted) {
                    this.nuxtApp.$csrf.retryAttempted = true;
                    await this.nuxtApp.$csrf();
                    return this.execute(endpoint, method, body); // Retry the API call
                } else {
                    throw new Error('CSRF token is invalid. Retry failed.');
                }
            } else if (error?.response?.status === 422) {
                console.log(error.data, 'foooo')
                let message = '';
                try {
                    message = error.data.message;
                } catch (e) {
                    message = "An error had occurred";
                }
                this.toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: message,
                    life: 3000,
                });

                throw new Error('Validation error');
            }

            return error
        }

    }
}

export default Api;